import { CSSProperties, Fragment, MouseEvent } from 'react'
import { components, ClearIndicatorProps, DropdownIndicatorProps, MenuListProps, OptionProps } from 'react-select'
import classNames from 'classnames'

import Icon from 'components/Icon'
import ButtonLink from 'components/ButtonLink'
import SquareImage from 'components/SquareImage'

import { GlobalSearchOption } from './GlobalSearch'
import useGlobalSearch from './useGlobalSearch'

import styles from './GlobalSearch.module.css'
const cx = classNames.bind(styles)

const GlobalSearchCustomComponents = () => {
  const { DropdownIndicator, Option } = components
  const { storeRecentSearchesInLocalStorage } = useGlobalSearch()

  const clearIndicator = (props: ClearIndicatorProps<GlobalSearchOption>) => {
    const {
      children = <Icon kind="x" size={16} className={styles.dismiss} />,
      getStyles,
      innerProps: { ref, ...restInnerProps }
    } = props
    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props) as CSSProperties}>
        <div className={styles.clearIcon}>{children}</div>
      </div>
    )
  }

  const dropdownIndicator = (props: DropdownIndicatorProps<GlobalSearchOption>) => {
    return (
      <>
        {Boolean(props.selectProps.value) ? null : (
          <DropdownIndicator {...props}>
            <Icon kind="search" size={16} className={styles.search} />
          </DropdownIndicator>
        )}
      </>
    )
  }

  const customMenuList = ({ ...props }: MenuListProps<GlobalSearchOption>) => {
    const { children, getValue, options, hasValue } = props
    const [value] = getValue()

    const isQueryStoredInRecentSearches: boolean = options.some(
      option => option.label?.toLowerCase() === value?.label.toLowerCase() && option.hasOwnProperty('searchResultUrl')
    )

    const topResult = Array.isArray(children)
      ? children
          .filter(child => child.props.data.type == 'sellers')
          .filter(child => child.props.data.label.toLowerCase() === value?.label.toLowerCase())
      : []
    const productsList = Array.isArray(children) ? children.filter(child => child.props.data.type == 'products') : []
    const sellersList = Array.isArray(children)
      ? children.filter(child => child.props.data.type == 'sellers').filter(child => child.key !== topResult[0]?.key)
      : []
    const brandValuesList = Array.isArray(children)
      ? children.filter(child => child.props.data.type == 'brandValues')
      : []
    const categoriesList = Array.isArray(children)
      ? children.filter(child => child.props.data.type == 'productCategoryTaxonomies')
      : []
    const recentSearchQueriesList = Array.isArray(children)
      ? children.filter(child => child.props.data.type == undefined)
      : []

    return Array.isArray(children) ? (
      !hasValue || (hasValue && isQueryStoredInRecentSearches) ? (
        <>
          {recentSearchQueriesList.length > 1 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Recent searches</p>
              {recentSearchQueriesList.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}

          {productsList.length > 0 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Products</p>
              {productsList.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}

          {sellersList.length > 0 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Sellers</p>
              {sellersList.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}

          {brandValuesList.length > 0 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Brand Values</p>
              {brandValuesList.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}

          {categoriesList.length > 0 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Categories</p>
              {categoriesList.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}
        </>
      ) : (
        <>
          {topResult.length === 1 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Top Result</p>
              {topResult.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}

          {productsList.length > 0 && (
            <div className={styles.subMenuList}>
              <div className={styles.headerContent}>
                <p className={styles.subcomponentTitle}>Products</p>
                <ButtonLink
                  className={styles.showAllResultsBtn}
                  kind="link"
                  label="Show All Results"
                  href={`/search?q=${value.label}`}
                  icon="arrow-right"
                  iconPosition="right"
                  size="mini"
                  onClick={() =>
                    storeRecentSearchesInLocalStorage({
                      query: value.label,
                      url: `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/search?q=${value.label}`
                    })
                  }
                />
              </div>

              <div className={styles.productsListContainer}>
                {productsList.map((child, index) => {
                  return <Fragment key={index}>{child}</Fragment>
                })}
              </div>
            </div>
          )}

          {sellersList.length > 0 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Sellers</p>
              {sellersList.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}

          {brandValuesList.length > 0 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Brand Values</p>
              {brandValuesList.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}

          {categoriesList.length > 0 && (
            <div className={styles.subMenuList}>
              <p className={styles.subcomponentTitle}>Categories</p>
              {categoriesList.map((child, index) => {
                return <Fragment key={index}>{child}</Fragment>
              })}
            </div>
          )}
        </>
      )
    ) : (
      <></>
    )
  }

  const customOption = (props: OptionProps<GlobalSearchOption>) => {
    const { data, children, isFocused } = props

    if (!data || data.label === '') {
      return null
    }

    const commonProps = {
      ...props,
      className: styles.optionContainer
    }

    const disableLink = (event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
      // Prevent the default behavior (navigation)
      event.preventDefault()
    }

    const getOptionContent = () => {
      if (data.hasOwnProperty('searchResultUrl')) {
        const categoryPath = `${data.label?.split('>').slice(0, -1).join('鈫�')} 鈫抈
        const isRootCategory = categoryPath === ' 鈫�'
        const productCategoryName = data.label?.split('>').slice(-1)[0]

        // Show path if product category, else show label only
        return (
          <>
            {data.type === 'productCategoryTaxonomies' ? (
              <a className={styles.url} href={data.searchResultUrl} onClick={disableLink}>
                <div className={styles.optionDetails}>
                  <div className={styles.productCategories}>
                    {isRootCategory ? null : categoryPath}
                    <p className={styles.productCategoryName}>{productCategoryName}</p>
                  </div>
                </div>
              </a>
            ) : (
              <a className={styles.url} href={data.searchResultUrl} onClick={disableLink}>
                <div className={styles.optionDetails}>
                  <p>{children}</p>
                </div>
              </a>
            )}
          </>
        )
      }

      switch (data.type) {
        case 'products':
          return (
            <a
              className={styles.url}
              href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}${data.marketplacePath}`}
              onClick={disableLink}>
              <div className={styles.previewProductItem}>
                <SquareImage className={styles.productImage} alt={data.label} src=/_next/static/chunks/{data.logoThumbUrl} cover />
                <p className={styles.productName}>{data.label}</p>
                <p className={styles.catalogName}>{data.catalogName}</p>
              </div>
            </a>
          )

        case 'sellers':
          return (
            <a
              className={styles.url}
              href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${data.slug}`}
              onClick={disableLink}>
              <div className={styles.optionDetails}>
                <img src=/_next/static/chunks/{data.logoThumbUrl} alt={`Wholesale ${data.catalogName}`} className={styles.sellerLogo} />
                <div className={styles.sellerInfo}>
                  <p className={styles.sellerName}>{data.label}</p>
                  <p className={styles.catalogName}>{data.catalogName}</p>
                </div>
              </div>
            </a>
          )

        case 'brandValues':
          return (
            <a
              className={styles.url}
              href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/shop?topBrandValueIds=${data.value}`}
              onClick={disableLink}>
              <div className={styles.optionDetails}>
                <p className={styles.brandValue}>{data.label}</p>
              </div>
            </a>
          )

        case 'productCategoryTaxonomies':
          const categoryPath = `${data.fullPath?.split('>').slice(0, -1).join('鈫�')} 鈫抈
          const isRootCategory = categoryPath === ' 鈫�'

          return (
            <a
              className={styles.url}
              href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/shop/c/${data.slugPath?.join('/')}`}
              onClick={disableLink}>
              <div className={styles.optionDetails}>
                <div className={styles.productCategories}>
                  {isRootCategory ? null : categoryPath} <p className={styles.productCategoryName}>{data.label}</p>
                </div>
              </div>
            </a>
          )

        default:
          return (
            <a
              className={styles.url}
              href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/search?q=${data.label}`}
              onClick={disableLink}>
              <div className={styles.optionDetails}>
                <p>{children}</p>
              </div>
            </a>
          )
      }
    }

    return (
      <Option {...commonProps}>
        {getOptionContent()}
        {(data.type !== 'products' || (data.type === 'products' && data.searchResultUrl)) && (
          <Icon kind="chevron-right" size={16} className={cx(styles.chevronRight, { [styles.isFocused]: isFocused })} />
        )}
      </Option>
    )
  }

  return {
    clearIndicator,
    dropdownIndicator,
    customOption,
    customMenuList
  }
}

export default GlobalSearchCustomComponents
